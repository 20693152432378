import React from 'react'
import NotFoundImg from "../assets/images/notfound.png"
import { Link } from 'react-router-dom'

const Notfound = () => {
  return (
    <div className='not_found'>
     <img src={NotFoundImg} alt='NotFound'/>
     <h4>Oops! Page Not found</h4>
<Link to={"/"}>
<button className='not-found_btn'>Go back to Register</button>
</Link>
    </div>
  )
}

export default Notfound