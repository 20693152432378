import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import images1 from "../../assets/images/ragestration.png";
import { useNavigate } from "react-router-dom";
import "./ragistration.css";

export default function Registration() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    mobile_number: "",
    email: "",
    address: "",
    password: "",
    confirm_password: "",
    additional_info: "",
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [mobileError, setMobileError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
      setFormData((prevState) => ({
        ...prevState,
        [name]: filteredValue,
      }));
    } else if (name === "mobile_number") {
      const filteredValue = value.replace(/[^0-9]/g, "").slice(0, 10);
      setFormData((prevState) => ({
        ...prevState,
        [name]: filteredValue,
      }));
      setMobileError("");
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    if (formData.mobile_number.length !== 10) {
      setMobileError("Mobile number must be exactly 10 digits.");
      return false;
    }
    if (formData.password !== formData.confirm_password) {
      toast.error("Passwords do not match!");
      return false;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      toast.error(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
      );
      return false;
    }
    return true;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted"); // Debug log
    if (validateForm()) {
      console.log("Form validated, opening modal"); // Debug log
      setIsModalOpen(true);
    }
  };

  const handlePayment = async () => {
    if (!isTermsChecked) {
      toast.error("Please accept the terms and conditions to proceed.");
      return;
    }

    try {
      const orderResponse = await axios.post(
        "https://font-controller-backend-ashy.vercel.app/api/v1/register/createOrder"
      );

      if (!orderResponse.data.success) {
        toast.error("Failed to create order. Please try again.");
        return;
      }

      const { orderId, amount } = orderResponse.data.data;

      const options = {
        key: "rzp_live_1hvthFrPDA4yds",
        amount: amount,
        currency: "INR",
        name: "Sylfaen Payment",
        description:
          "Sylfaen font selector to write using multiple fonts as per needs",
        image: "https://cdn-icons-png.flaticon.com/256/3917/3917754.png",
        order_id: orderId,
        handler: async function (response) {
          try {
            const backendResponse = await axios.post(
              "https://font-controller-backend-ashy.vercel.app/api/v1/register/add",
              {
                ...formData,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: orderId,
              }
            );

            // Check if user registration was successful
            if (backendResponse.data.success) {
              localStorage.setItem(
                "razorpay_payment_id",
                response.razorpay_payment_id
              );
              toast.success("Registration and payment successful!");
              setIsModalOpen(false);

              navigate("/response", {
                state: {
                  name: formData.name,
                  email: formData.email,
                  mobile_number: formData.mobile_number,
                  address: formData.address,
                  amount: backendResponse.data.data.amount,
                  date: new Date().toLocaleDateString(),
                },
              });
            } else {
              toast.error("Registration failed after payment.");
            }
          } catch (error) {
            console.error("There was an error during registration!", error);
            toast.error("Registration failed after payment.");
          }
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.mobile_number,
        },
        notes: {
          address: "Your Company Address",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      rzp1.on("payment.failed", function (response) {
        toast.error("Payment failed. Please try again.");
      });
    } catch (error) {
      console.error("There was an error creating the order!", error);
      toast.error("Failed to create order. Please try again.");
    }

    setIsModalOpen(false);
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <>
      <div className="App">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 image-container">
              <img
                src={images1}
                alt="Registration Illustration"
                className="registration-image"
              />
            </div>
            <div className="col-lg-6 col-md-12 form-container">
              <h2>Sylfaen Registration</h2>
              <form onSubmit={handleSubmit} className="form-inputt">
                <div className="row">
                  <div className="form-group col-6">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter your name"
                      onChange={handleChange}
                      value={formData.name}
                      required
                    />
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="mobile_number">Mobile</label>
                    <input
                      type="text"
                      id="mobile_number"
                      name="mobile_number"
                      placeholder="Enter your mobile number"
                      onChange={handleChange}
                      value={formData.mobile_number}
                      required
                    />
                    {mobileError && (
                      <div className="error-message">{mobileError}</div>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      value={formData.email}
                      required
                    />
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="password">Create Password</label>
                    <div className="password-container">
                      <input
                        type={showPassword.password ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Create your password"
                        onChange={handleChange}
                        value={formData.password}
                        required
                      />
                      <button
                        type="button"
                        onClick={() => togglePasswordVisibility("password")}
                        className="password-toggle-btn-registration"
                      >
                        <FontAwesomeIcon
                          icon={showPassword.password ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="confirm_password">Confirm Password</label>
                    <div className="password-container">
                      <input
                        type={
                          showPassword.confirmPassword ? "text" : "password"
                        }
                        id="confirm_password"
                        name="confirm_password"
                        placeholder="Confirm your password"
                        onChange={handleChange}
                        value={formData.confirm_password}
                        required
                      />
                      <button
                        type="button"
                        onClick={() =>
                          togglePasswordVisibility("confirmPassword")
                        }
                        className="password-toggle-btn-registration1"
                      >
                        <FontAwesomeIcon
                          icon={
                            showPassword.confirmPassword ? faEyeSlash : faEye
                          }
                        />
                      </button>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="amount">Amount (Incl.18% GST)</label>
                    <input
                      type="number"
                      id="amount"
                      name="amount"
                      placeholder="Pay 20,886 INR Rupees"
                      disabled
                      required
                    />
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="address">Address</label>
                    <textarea
                      id="address"
                      name="address"
                      placeholder="Enter your address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Additional Information</label>
                    <textarea
                      id="additional_info"
                      name="additional_info"
                      placeholder="Enter additional information"
                      value={formData.additional_info}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <button type="submit" className="submit-btn">
                  Submit
                </button>
                <div className="already-registered">
                  <p style={{ fontSize: "14px" }}>
                    Already registered?
                    <a href="/login" className="login-link">
                      Login
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div
          className="modal"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className="modal-content"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "600px",
            }}
          >
            <h2>End User License Agreement (EULA)</h2>
            <div
              style={{
                height: "300px",
                overflowY: "scroll",
                border: "1px solid #ccc",
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "12px",
              }}
            >
              {/* <h4>End User License Agreement (EULA)</h4> */}
              <p style={{ fontSize: "14px" }}>
                <p style={{ fontSize: "14px" }}>
                  <strong>1.</strong> The Sylfaen is shareware. This means that
                  the exclusive rights to Sylfaen are owned by the author,
                  Yogesh S Jagdale.
                </p>
                <p style={{ fontSize: "14px" }}>
                  <strong>2. Usage Terms:</strong>
                </p>
                <p style={{ fontSize: "14px" }}>
                  2.1 Sylfaen is not free software. To use Sylfaen, you must
                  purchase a license before or upon installation. Continued use
                  without a valid license is strictly prohibited.
                </p>
                <p style={{ fontSize: "14px" }}>
                  <strong>3. Types of Licenses:</strong>
                </p>
                <p style={{ fontSize: "14px" }}>
                  3.1 <strong>Single Computer Usage License:</strong> One
                  license for use on one computer. Home users can use this on
                  all personal computers they own, but business users must have
                  a separate license for each computer where Sylfaen is
                  installed.
                </p>
                <p style={{ fontSize: "14px" }}>
                  <strong>4. Purchase of License:</strong>
                </p>
                <p style={{ fontSize: "14px" }}>
                  4.1 Information on purchasing a license is provided in the
                  "Invoice" file or the official Sylfaen website.
                </p>
                <p style={{ fontSize: "14px" }}>
                  <strong>5. Warranty Disclaimer:</strong>
                </p>
                <p style={{ fontSize: "14px" }}>
                  5.1 Sylfaen is distributed "as is," with no warranties
                  expressed or implied. The author and agents are not liable for
                  any damages or losses resulting from the use or misuse of the
                  software.
                </p>
                <p style={{ fontSize: "14px" }}>
                  <strong>6. Restrictions:</strong>
                </p>
                <p style={{ fontSize: "14px" }}>
                  6.1 Users may not copy, emulate, clone, rent, lease, sell,
                  modify, decompile, or disassemble the software, except as
                  allowed by this agreement. Any such unauthorized usage may
                  result in license termination and appropriate criminal and
                  civil action.
                </p>
                <p style={{ fontSize: "14px" }}>
                  6.2 Sylfaen binary code may not be used to reverse engineer or
                  recreate the software without written permission from the
                  author.
                </p>
                <p style={{ fontSize: "14px" }}>
                  <strong>7. License Key Distribution:</strong>
                </p>
                <p style={{ fontSize: "14px" }}>
                  7.1 Installer for Sylfaen may not be distributed without
                  permission, except as stated in this agreement.
                </p>
                <p style={{ fontSize: "14px" }}>
                  <strong>8. Font Usage Clause:</strong>
                </p>
                <p style={{ fontSize: "14px" }}>
                  8.1 The Sylfaen font, used in conjunction with Sylfaen or its
                  distribution, is subject to licensing and ownership rights.
                  The owner of the Sylfaen font is Yogesh S Jagdale. Any use or
                  distribution of Sylfaen must comply with the terms set forth
                  by the owner and applicable licensing agreements.
                </p>
                <p style={{ fontSize: "14px" }}>
                  <strong>9. Agreement Acceptance:</strong>
                </p>
                <p style={{ fontSize: "14px" }}>
                  9.1 Installing and using Sylfaen signifies acceptance of this
                  license. If you disagree with any terms, you must remove
                  Sylfaen from your storage devices and cease its use.
                </p>
                <p style={{ fontSize: "14px" }}>
                  <strong>10. Author Information:</strong>
                </p>
                <p style={{ fontSize: "14px" }}>
                  10.1 The COPYRIGHT ,TRADEMARK AND IPR holder of Sylfaen is{" "}
                  <strong>Yogesh S Jagdale </strong>.
                </p>
              </p>

              <p style={{ fontSize: "14px" }}>
                <strong>1. DEFINITIONS</strong>
                <br />
                1.1 <b>Software:</b> : The term "Software" refers to the Sylfaen
                font software developed and owned by Licensor, which includes
                any associated documentation, updates, upgrades, and
                modifications provided by Licensor.
                <br />
                1.2 <b>User: </b>: The term "User" refers to any individual or
                entity that uses or accesses the Software
                <br />
                1.3 <b>License:</b> The term "License" refers to the rights
                granted to Licensee under this Agreement to use the Software.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>2. GRANT OF LICENSE</strong>
                <br />
                2.1 <b>License Grant:</b> Licensor hereby grants Licensee a
                non-exclusive, non-transferable license to install and use the
                Software on a single computer or device, subject to the terms
                and conditions of this Agreement.
                <br />
                2.2 <b>Multiple Licenses:</b> If Licensee requires the Software
                for multiple computers or devices, Licensee must purchase
                additional licenses.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>3. OWNERSHIP AND INTELLECTUAL PROPERTY</strong>
                <br />
                3.1 <b>Ownership:</b> Licensee acknowledges that the Software is
                the property of Licensor and is protected by copyright,
                trademark, and other intellectual property laws. All rights not
                expressly granted to Licensee in this Agreement are reserved by
                Licensor.
                <br />
                3.2 <b>Trademarks:</b> The name "Sylfaen" and any related logos
                or branding are trademarks of Licensor. Licensee may not use
                these trademarks without Licensor's prior written consent.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>4. USE OF SOFTWARE</strong>
                <br />
                4.1 <b>Permitted Use:</b> : Licensee may use the Software to
                create, edit, and output documents in various languages as
                permitted by the Software's functionality.
                <br />
                4.2 <b>Restrictions:</b> Licensee may not:
                <br />
                • (a) reverse engineer, decompile, or disassemble the Software;
                <br />
                • (b) distribute, sell, or lease the Software to third parties;
                <br />
                • (c) modify or create derivative works based on the Software;
                or or
                <br />
                • (d) use the Software in any manner that violates applicable
                laws or regulations.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>5. LICENSE FEES</strong>
                <br />
                5.1 <b>License Fees:</b> Licensee agrees to pay the license fees
                as specified on Licensor's website or any other location
                designated by Licensor.
                <br />
                5.2 <b>Payment Terms:</b> All payments shall be made in
                accordance with Licensor's payment terms and conditions.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>6. SUPPORT AND UPDATES</strong>
                <br />
                6.1 <b>Support:</b> Licensor may provide support for the
                Software as specified on its website or other documentation.
                Licensor is not obligated to provide support for any version of
                the Software that is not the most current version.
                <br />
                6.2 <b>Updates:</b> Licensor may, at its discretion, provide
                updates or upgrades to the Software. Licensee agrees to install
                any updates or upgrades to continue using the Software.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>7. TERMINATION</strong>
                <br />
                7.1 <b>Termination:</b> This Agreement will terminate
                automatically if Licensee fails to comply with any of its terms
                and conditions. Upon termination, Licensee must immediately
                cease all use of the Software and destroy all copies of the
                Software in Licensee's possession.
                <br />
                7.2 <b>Survival:</b> The provisions of Sections 3, 4, 5, 7, 8,
                and 9 shall survive termination of this Agreement.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>8. WARRANTY DISCLAIMER</strong>
                <br />
                8.1 <b>No Warranty:</b> Licensor makes no warranties, express or
                implied, regarding the Software, including but not limited to
                any implied warranties of merchantability or fitness for a
                particular purpose. Licensee acknowledges that the Software is
                provided "as is" and that Licensee assumes all risks associated
                with its use.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>9. LIMITATION OF LIABILITY</strong>
                <br />
                9.1 <b>Limitation of Liability:</b> In no event shall Licensor
                be liable for any indirect, incidental, special, consequential,
                or punitive damages arising from or related to this Agreement or
                the use of the Software, even if Licensor has been advised of
                the possibility of such damages.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>10. INDEMNIFICATION</strong>
                <br />
                10.1 <b> Indemnification: </b> Licensee agrees to indemnify,
                defend, and hold harmless Licensor from any claims, losses,
                liabilities, damages, costs, and expenses (including reasonable
                attorney's fees) arising from Licensee's use of the Software or
                any breach of this Agreement.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>11. GOVERNING LAW</strong>
                <br />
                11.1 <b> Governing Law:</b> This Agreement shall be governed by
                and construed in accordance with the laws of [State/Country],
                without regard to its conflict of law principles.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>12. ENTIRE AGREEMENT</strong>
                <br />
                12.1 <b> Entire Agreement: </b>This Agreement constitutes the
                entire agreement between Licensor and Licensee concerning the
                Software and supersedes all prior agreements and understandings,
                whether written or oral, regarding the Software.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>13. AMENDMENTS</strong>
                <br />
                13.1 <strong>Amendments </strong>: Any amendments or
                modifications to this Agreement must be in writing and signed by
                both parties.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>14. SEVERABILITY</strong>
                <br />
                14.1 <strong>Severability</strong>: If any provision of this
                Agreement is found to be unenforceable or invalid, the remaining
                provisions shall continue to be valid and enforceable to the
                fullest extent permitted by law.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>15. ASSIGNMENT</strong>
                <br />
                15.1<strong> Assignment:</strong> Licensee may not assign or
                transfer any rights or obligations under this Agreement without
                the prior written consent of Licensor. Licensor may assign or
                transfer this Agreement, in whole or in part, at its sole
                discretion.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>16. NOTICES</strong>
                <br />
                16.1<strong> Notices: </strong> Any notices or communications
                required or permitted under this Agreement shall be in writing
                and shall be deemed given when delivered personally, sent by
                certified mail, or sent via email to the addresses specified by
                the parties.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>17. FORCE MAJEURE</strong>
                <br />
                17.1 <strong> Force Majeure:</strong> Licensor shall not be
                liable for any failure to perform its obligations under this
                Agreement due to circumstances beyond its reasonable control,
                including but not limited to acts of God, war, terrorism, labor
                disputes, or governmental actions.
                <br />
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>18. CONTACT INFORMATION</strong>
                <br />
                18.1<strong> Contact Information:</strong> For any questions or
                concerns regarding this Agreement, please contact:
                <br />
                <br />
                Author: <strong> Yogesh S Jagdale</strong>
                <br />
                Address:{" "}
                <strong>
                  XUV TECHNOLOGY F 96, The Zone Chandavarkar Road, Borivali West
                  Mumbai 400092
                </strong>
                <br />
                Email:{" "}
                <a href="mail.com" style={{ textDecoration: "none" }}>
                  <strong>sylfaen.software@gmail.com</strong>
                </a>
                <br />
                Phone: <strong>+91 9323572423</strong>
              </p>
            </div>
            <div className="form-group">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div className="checkbox-large justify-center">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={isTermsChecked}
                    onChange={() => setIsTermsChecked(!isTermsChecked)}
                  />
                  <label htmlFor="terms"></label>
                </div>
                <label
                  htmlFor="terms"
                  style={{
                    whiteSpace: "nowrap",
                    marginLeft: 10,
                    marginBottom: 10,
                  }}
                >
                  I agree to the terms and conditions
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => setIsModalOpen(false)}
                className="submit-btn"
                style={{ backgroundColor: "#6c757d" }}
              >
                Cancel
              </button>
              <button
                onClick={handlePayment}
                className="submit-btn"
                disabled={!isTermsChecked}
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}
