import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../layout';
import './index.css';

function Admin() {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(7);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (!isLoggedIn) {
            navigate('/admin/login', { replace: true }); 
        } else {
            fetchUsers(); 
        }
    }, [navigate]);

    const fetchUsers = () => {
        axios.get('https://font-controller-backend-ashy.vercel.app/api/v1/register/get')
            .then(response => {
                if (response.data.success) {
                    setUsers(response.data.data);
                } else {
                    console.error('Failed to fetch user data');
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    /*
    useEffect(() => {
        axios.get('https://font-controller-backend-ashy.vercel.app/api/v1/register/get')
            .then(response => {
                if (response.data.success) {
                    setUsers(response.data.data);
                } else {
                    console.error('Failed to fetch user data');
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);
    */

    const handleEdit = async (userId) => {
        setLoading(true); 
        try {
          
            await new Promise(resolve => setTimeout(resolve, 500)); 
            navigate(`/admin/edit/${userId}`);
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (userId) => {
        setLoading(true); 
        try {
            console.log('Delete user with ID:', userId);
            const response = await axios.post(`https://font-controller-backend-ashy.vercel.app/api/v1/register/${userId}`);
            if (response.data.success) {
                toast.success('User deleted successfully!');
             
                const updatedResponse = await axios.get('https://font-controller-backend-ashy.vercel.app/api/v1/register/get');
                if (updatedResponse.data.success) {
                    setUsers(updatedResponse.data.data);
                } else {
                    console.error('Failed to fetch user data after deletion');
                }
            } else {
                console.log('Failed to delete user:', response.data.message);
                toast.error('Failed to delete user: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('An error occurred while deleting the user.');
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = () => {
        navigate('/admin/add');
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

   
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = users.slice(indexOfFirstRow, indexOfLastRow);

    return (
        <Layout>
            <div className="admin-dashboard">
                <ToastContainer /> {/* This must be included for the toasts to show */}
                {loading && (
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
                <div className="admin-main">
                    <section className="admin-table-section">
                        <table className="admin-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Password</th>
                                    <th>Amount</th>
                                    <th>Additional Info</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows.map(user => (
                                    <tr key={user._id}>
                                        <td>{user.name}</td>
                                        <td>{user.mobile_number}</td>
                                        <td>{user.email}</td>
                                        <td>{user.password}</td>
                                        <td>{user.amount}</td>
                                        <td>{user.additional_info}</td>
                                        <td>
                                            <FaEdit
                                                onClick={() => handleEdit(user._id)}
                                                className="action-icon edit-icon"
                                            />
                                            <FaTrash
                                                onClick={() => handleDelete(user._id)}
                                                className="action-icon delete-icon"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            {Array.from({ length: Math.ceil(users.length / rowsPerPage) }, (_, i) => (
                                <button
                                    key={i + 1}
                                    onClick={() => handlePageChange(i + 1)}
                                    className={`pagination-btn ${currentPage === i + 1 ? 'active' : ''}`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    );
}

export default Admin;
