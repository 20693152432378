import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './addUser.css';
import Layout from '../../layout';

function AddUser() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        mobile_number: '',
        email: '',
        password: '',
        confirm_password: '',
        amount: '',
        additional_info: ''
    });

    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false
    });

    const [amountError, setAmountError] = useState('');
    const [mobileError, setMobileError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'name') {
            const filteredValue = value.replace(/[0-9]/g, '');
            setFormData((prevState) => ({
                ...prevState,
                [name]: filteredValue,
            }));
        } else if (name === 'mobile_number') {
            const filteredValue = value.replace(/[^0-9]/g, '').slice(0, 10);
            setFormData((prevState) => ({
                ...prevState,
                [name]: filteredValue,
            }));
            setMobileError('');
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

        if (name === 'amount') {
            if (value && value < 1000) {
                setAmountError('Amount must be at least 1000 INR.');
            } else {
                setAmountError('');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.mobile_number.length !== 10) {
            setMobileError('Mobile number must be exactly 10 digits.');
            return;
        }

        if (formData.amount === '' || formData.amount < 1000) {
            if (formData.amount !== '') {
                setAmountError('Amount must be at least 1000 INR.');
            }
            return;
        } else {
            setAmountError('');
        }

        if (formData.password !== formData.confirm_password) {
            alert("Passwords do not match!");
            return;
        }

        try {
            // Send the form data to the backend to create a new user
            await axios.post('https://font-controller-backend-ashy.vercel.app/api/v1/register/add', formData);
            alert('User added successfully!');
            navigate('/admin/dashboard'); // Navigate back to the admin page after successful creation
        } catch (error) {
            console.error('There was an error creating the user!', error);
            alert('Failed to create user.');
        }
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    return (
        <Layout>
            <div className="form-container-edit">
                <div className="form-wrapper">
                    <h2>Add User</h2>
                    <div className='row'>
                        <form onSubmit={handleSubmit}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="form-group col-6">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Enter your name"
                                        onChange={handleChange}
                                        value={formData.name}
                                        required
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="mobile_number">Mobile</label>
                                    <input
                                        type="text"
                                        id="mobile_number"
                                        name="mobile_number"
                                        placeholder="Enter your mobile number"
                                        onChange={handleChange}
                                        value={formData.mobile_number}
                                        required
                                    />
                                    {mobileError && <div className="error-message">{mobileError}</div>}
                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    onChange={handleChange}
                                    value={formData.email}
                                    required
                                />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="password">Create Password</label>
                                <div className="password-container">
                                    <input
                                        type={showPassword.password ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        placeholder="Create password"
                                        onChange={handleChange}
                                        value={formData.password}
                                        required
                                    />
                                    <button type="button" onClick={() => togglePasswordVisibility('password')} className="password-toggle-btn">
                                        <FontAwesomeIcon icon={showPassword.password ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="confirm_password">Confirm Password</label>
                                <div className="password-container">
                                    <input
                                        type={showPassword.confirmPassword ? "text" : "password"}
                                        id="confirm_password"
                                        name="confirm_password"
                                        placeholder="Confirm password"
                                        onChange={handleChange}
                                        value={formData.confirm_password}
                                        required
                                    />
                                    <button type="button" onClick={() => togglePasswordVisibility('confirmPassword')} className="password-toggle-btn">
                                        <FontAwesomeIcon icon={showPassword.confirmPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="amount">Amount</label>
                                <input
                                    type="string"
                                    id="amount"
                                    name="amount"
                                    placeholder="Pay 1000 INR Rupees"
                                    onChange={handleChange}
                                    value={formData.amount}
                                    required
                                />
                                {amountError && <div className="error-message">{amountError}</div>}
                            </div>

                            <div className="form-group col-12">
                                <label>Additional info</label>
                                <textarea
                                    id="additional_info"
                                    name="additional_info"
                                    placeholder="Enter your messages"
                                    rows="3"
                                    onChange={handleChange}
                                    value={formData.additional_info}
                                />
                            </div>
                            <button type="submit" className="submit-btn">Add User</button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AddUser;
