import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Admin from "./components/Home/Admin";
import Registration from "./components/Registration/Registration";
import EditUser from "./components/CRUD/edit/editUser";
import AddUser from "./components/CRUD/add/addUser";
import AdminLogin from "./components/login/index";
import Notfound from "./components/Notfound";
import Response from "./components/Response/Response";
import Login from "./components/login/Login";
import Recoverysoftware from "./components/Recovery/Recoverysoftware";
import ProtectedRoute from "./components/Protected-Route";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Registration />} />
        <Route path="/response" element={<Response />} />
        {/* <Route path="/recover" element={<Recoverysoftware />} /> */}
        <Route
          path="/recover-sylfaen-software"
          element={
            <ProtectedRoute>
              <Recoverysoftware />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<Admin />} />
        <Route path="/admin/edit/:userId" element={<EditUser />} />
        <Route path="/admin/add" element={<AddUser />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </Router>
  );
}

export default App;
