import React from "react";
import "./Recoverysoftware.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceDocument from "../invoice.js";
import { useLocation, useNavigate } from "react-router-dom";

const generateInvoiceNumber = () => {
  const now = new Date();
  const year = now.getFullYear();
  const randomNum = Math.floor(1000 + Math.random() * 9000);
  return `INV-${year}-${randomNum}`;
};

const getCurrentDate = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const year = now.getFullYear();
  return `${day}-${month}-${year}`;
};

export default function Recoverysoftware() {
  const location = useLocation();
  const navigate = useNavigate();

  // Retrieve data from location state or set default values
  const {
    name,
    email,
    address,
    amount = 17700,
    mobile_number,
    gst = 3186,
    totalAmount,
  } = location.state || {};
  const invoiceNo = generateInvoiceNumber();
  const invoiceDate = getCurrentDate();

  // Calculate totalAmount if not provided
  const calculatedTotalAmount = totalAmount || amount + gst;

  const invoiceData = {
    invoiceNo: invoiceNo,
    date: invoiceDate,
    billTo: {
      name: name || "Customer Name",
      address: address || "Customer Address",
      email: email || "Customer Email",
      mobile: mobile_number || "1234567891",
    },
    from: {
      name: "XUV TECHNOLOGY",
      address: "F 96, The Zone Chandavarkar Road, Borivali West Mumbai 400092",
      email: "sylfaen.software@gmail.com",
      mobile: "9323572423",
    },
    items: [
      {
        name: "Software License",
        quantity: 1,
        price: amount,
        amount: amount,
        gst: gst,
        totalAmount: calculatedTotalAmount,
      },
    ],
    total: calculatedTotalAmount,
    paymentMethod: "Online",
    note: "Thank you for your purchase!",
  };

  const handleDownloadAndLogout = () => {
    window.location.href =
      "https://sylfaen-font-installer.s3.ap-south-1.amazonaws.com/SYLFAEN.exe.zip";
    setTimeout(() => {
      localStorage.removeItem("authToken");
      navigate("/login");
    }, 30000);
  };

  return (
    <div className="recover_container">
      <div className="recover_content">
        <h1>
          WELCOME <span>SYLFAEN</span>
        </h1>
        <p>Recover the page download software and PDF</p>
        <div className="download">
          {/* Download Receipt Button */}
          <PDFDownloadLink
            document={<InvoiceDocument data={invoiceData} />}
            fileName="invoice.pdf"
            className="download_btn"
          >
            {({ loading }) =>
              loading ? "Generating receipt..." : "Download Receipt"
            }
          </PDFDownloadLink>

          {/* Download Software Button */}
          <button onClick={handleDownloadAndLogout} className="download_btn">
            Download Software
          </button>
        </div>
      </div>
    </div>
  );
}
